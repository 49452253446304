import { Controller } from "stimulus"
import Highcharts from 'highcharts';
import downloadSvg from '../../assets/images/download.svg';
require("highcharts/highcharts-more")(Highcharts)

export default class extends Controller {
  connect() {
    const chartData = JSON.parse(this.data.get('chart-data'));
    const percents = JSON.parse(this.data.get('percents'));
    const color = JSON.parse(this.data.get('chart-color'));
    const names = Object.keys(chartData).filter(key => chartData[key].hasOwnProperty('name')).map(key => chartData[key].name);
    const values = Object.keys(chartData).filter(key => chartData[key].hasOwnProperty('data')).map(key => chartData[key].data);
    Highcharts.chart(this.element, {
      chart: {
        type: 'bar',
        style: {
          overflow: 'visible'
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: names,
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: false
        },
        title: {
          enabled: false
        }
      },
      legend: {
        reversed: true,
      },
      plotOptions: {
        series: {
          colorByPoint: true, // Enable color by point
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            align: 'right',
            x: 50,
            formatter: function () {

              return this.y === 0 ? '' : '(' + this.y + (percents ? "%" : "") + ')';
            }
          }
        }
      },
      series: [{
        name: '',
        data: values,
        showInLegend: false,
      }],

      colors: color, // Optional: Custom colors
      navigation: {
        buttonOptions: {
          align: 'right',
          verticalAlign: 'bottom',
          y: 50,
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            text: 'Download this graph',
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
            ],
            symbol: 'url(' + downloadSvg + ')',
            symbolX: 20,
            symbolY: 20,
            symbolStrokeWidth: 1,
            theme: {
              style: {
                color: '#4462E9',
              },
              fill: '#FFFFFF',
              stroke: '#4462E9',
              strokeWidth: 2,
              states: {
                hover: {
                  fill: '#D3D3D3'
                },
                select: {
                  fill: '#D3D3D3'
                }
              }
            }
          }
        }
      }
    });
  }
}
