import { Controller } from "stimulus"
import Highcharts from 'highcharts';
import downloadSvg from '../../assets/images/download.svg';
require("highcharts/highcharts-more")(Highcharts)
require('highcharts/modules/exporting')(Highcharts);


export default class extends Controller {
  connect() {
    const chartData = JSON.parse(this.data.get('chart-data'));

    Highcharts.chart(this.element, {
      chart: {
        type: 'bar',
        height: 145,
        marginLeft: 40,
        marginRight: 40,
        style: {
          overflow: 'visible' // Set overflow to visible to prevent hiding the download button
        },
        plotBorderColor: '#D9D9D9',  // Border color for the plot area
        plotBorderWidth: 1,
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: [],
        labels: {
          enabled: false
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        tickInterval: 20,
        labels: {
          format: '{value}%'
        },
        title: {
          enabled: false
        },
      },
      legend: {
        align: 'center',
        verticalAlign: 'top',
        symbolRadius: 0,
        squareSymbol: false,
        symbolHeight: 12,
        symbolWidth: 24,
        labelFormatter: function () {
          var count = this.userOptions?.count ?? "";
          return this.name + ' ' + count + ' (' + this.yData[0] + '%)';
        },
        navigation: {
          enabled: false
        },
        reversed: true
      },
      colors: ['#99CC00', '#4462E9', '#F15A22', '#74C4E8', '#F6B531', '#62479C'].slice(0, chartData.length).reverse(),
      plotOptions: {
        series: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          },
          pointWidth: 25, // Adjust the value to decrease the bar thickness
        }
      },
      series: chartData.reverse(),
      navigation: {
        buttonOptions: {
          align: 'right', // Position the button to the right
          verticalAlign: 'bottom', // Position the button at the bottom
          y: 50, // Adjust the value to control the vertical position of the button
        }
      },
      tooltip: {
        formatter: function () {
          // 'this' refers to the current data point
          var seriesName = this.series.name;
          var value = this.y;
          var color = this.series.color;
          return seriesName + ': ' + '<span style="color:' + color + '">\u25CF</span> ' + value + '%';
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            text: 'Download this graph',
            menuItems: [
              'downloadPNG',  // Download PNG option
              'downloadJPEG', // Download JPEG option
              'downloadPDF',  // Download PDF option
              'downloadSVG',  // Download SVG option
            ],
            symbol: 'url(' + downloadSvg + ')',  // Custom symbol for the button
            symbolX: 20, // Adjust the x offset for the symbol position
            symbolY: 20,  // Adjust the y offset for the symbol position
            symbolStrokeWidth: 1, // Custom symbol stroke width
            theme: {
              style: {
                color: '#4462E9',  // Custom text color (blue)
              },
              fill: '#FFFFFF',     // Custom button background color (white)
              stroke: '#4462E9',   // Custom button outline color (blue)
              strokeWidth: 2,      // Custom button outline stroke width
              states: {
                hover: {
                  fill: '#D3D3D3'  // Custom button background color on hover (light grey)
                },
                select: {
                  fill: '#D3D3D3'  // Custom button background color when selected
                }
              }
            }
          }
        }
      }
    });
  }
}