import {Controller} from 'stimulus';
import { createConfirmationModal } from '../utils/createModal';

export default class extends Controller {
  promptUser(e) {
    e.preventDefault()
    
    let modal = createConfirmationModal({
      message: this.data.get("message"),
      actionText: this.data.get("action-message"),
    })
    document.body.appendChild(modal)

    let actionButton = modal.querySelector("button") // This needs to be tweaked if we add more buttons to the modal
    actionButton.addEventListener("click", this.visit.bind(this))

    $(modal).modal('show')
  }

  visit() {
    // This method doesn't support the full gammut of Rails UJS confirm behavior, e.g. it won't support `data-method` annotations, we only do GET requests here
    let url = this.element.href
    if (window.Turbolinks) {
      Turbolinks.visit(url)
    } else {
      window.location = url
    }
  }
}