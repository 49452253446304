import { Controller } from "stimulus";
import Highcharts from 'highcharts';
import heatmap from 'highcharts/modules/heatmap'; // Import the heatmap module
import downloadSvg from '../../assets/images/download.svg';
heatmap(Highcharts); // Initialize the heatmap module
require("highcharts/highcharts-more")(Highcharts);
require('highcharts/modules/exporting')(Highcharts);

export default class extends Controller {

  connect() {
    const chartData = JSON.parse(this.data.get('chart-data'));
    const {x_demo, y_demo, x_title, y_title, series} = chartData;

    Highcharts.chart(this.element, {
      chart: {
        type: 'heatmap',
        marginTop: 70,
        marginBottom: 50,
        plotBorderWidth: 1,
        style: {
          overflow: 'visible' // Set overflow to visible to prevent hiding the download button
        }
      },

      title: {
        text: "",
        style: {
          fontSize: '1em'
        }
      },

      xAxis: {
        labels: {
          align: 'center'
        },
        title: {
          text: x_title,
          style: {
            fontWeight: 'bold'
          }
        },
        categories: x_demo,
        opposite: true  // Move x-axis labels to the top
      },
    
      yAxis: {
        labels: {
          useHTML: true,
          formatter: function() {
            const maxLength = 30;
            let label = this.value;
            if (label.length > maxLength) {
              label = label.substring(0, maxLength) + '...';
            }
            return '<div style="padding: 5px; text-align: right; width: 100%; margin: 0px;">' + label + '</div>';
          }
        },
        title: {
          text: y_title,
          style: {
            fontWeight: 'bold'
          }
        },
        
        categories: y_demo,
      },
      tooltip: {
        formatter: function() {
          // 'this' refers to the current data point
          var xValue = this.series.xAxis.categories[this.point.x]; // Get the x-axis category
          var yValue = this.series.yAxis.categories[this.point.y]; // Get the y-axis category
          var value = this.point.value; // Get the value of the data point
      
          // Construct the tooltip text
          var tooltipText = value + ' - ' + xValue + ' - ' + yValue;
          return tooltipText;
        }
      },
      accessibility: {
        point: {
          descriptionFormat: '{(add index 1)}. ' +
            '{series.xAxis.categories.(x)} sales ' +
            '{series.yAxis.categories.(y)}, {value}.'
        }
      },

      colorAxis: {
        min: 0,
        minColor: "#ffffff",
        maxColor: "#b4c0f6"
      },
    
      legend: {
        enabled: false
      },
    
      series: [{
        color: '#d9d9d9', // Set the line color here,
        borderWidth: 1,
        data: series,
        dataLabels: {
          enabled: true,
          color: '#000000',
          formatter: function() {
            if (this.point.value !== 0) {
              return this.point.value.toString(); // Display the value if not 0
            } else {
              return null; // Hide the label if the value is 0
            }
          }
        }
      }],
    
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            yAxis: {
              labels: {
                format: '{substr value 0 1}'
              }
            }
          }
        }]
      },
      navigation: {
        buttonOptions: {
          align: 'right', // Position the button to the right
          verticalAlign: 'bottom', // Position the button at the bottom
          y: 40, // Adjust the value to control the vertical position of the button
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            text: 'Download this graph',
            menuItems: [
              'downloadPNG',  // Download PNG option
              'downloadJPEG', // Download JPEG option
              'downloadPDF',  // Download PDF option
              'downloadSVG',  // Download SVG option
            ],
            symbol: 'url(' + downloadSvg + ')',  // Custom symbol for the button
            symbolX: 20, // Adjust the x offset for the symbol position
            symbolY: 20,  // Adjust the y offset for the symbol position
            symbolStrokeWidth: 1, // Custom symbol stroke width
            theme: {
              style: {
                color: '#4462E9',  // Custom text color (blue)
              },
              fill: '#FFFFFF',     // Custom button background color (white)
              stroke: '#4462E9',   // Custom button outline color (blue)
              strokeWidth: 2,      // Custom button outline stroke width
              states: {
                hover: {
                  fill: '#D3D3D3'  // Custom button background color on hover (light grey)
                },
                select: {
                  fill: '#D3D3D3'  // Custom button background color when selected
                }
              }
            }
          }
        }
      }
    });
  }
}
