import { Controller } from "stimulus"
import Highcharts from 'highcharts';
import downloadSvg from '../../assets/images/download.svg';
require("highcharts/highcharts-more")(Highcharts)
require('highcharts/modules/exporting')(Highcharts);


export default class extends Controller {
  connect() {
    let categoryData = JSON.parse(this.element.dataset.categories)
    
    Highcharts.chart(this.element, {
      chart: {
        polar: true,
        type: 'area'
      },
      plotOptions: {
        series: {
          fillOpacity: 0.15,
          animation: false
        },
        area: {
          pointPlacement: "on",
        }
      },
      colors: [
        "#4462E9",
        "#F15A22",
        "#00A36C"
      ],
      accessibility: {
        description: 'This dashboard reflects the overall test scores where a question tied to a domain, dimension, or competency appears in a test. This dashboard does not reflect how learners have scored at the domain, dimension, or competency level in their Career Compass.'
      },
      title: {
        text: '% of learners who passed',
      },  
      xAxis: {
        categories: categoryData,
        tickmarkPlacement: 'on',
        lineWidth: 0,
        labels: {
          useHTML: true,
          distance: 30,
          formatter: function() {
            let label = '<div class="p-2 border text-center rounded highcharts-label-hover">'
            label += '<a href="' + this.value.path + '" class="text-dark text-decoration-none">'
            label += this.value.name
            label += '</a>'
            label += '</div>'
            return label
          }
        }
      },
      yAxis: {
        gridLineInterpolation: 'polygon',
        tickInterval: 10,
        lineWidth: 0,
        min: 0,
        max: 100,
        labels: {
          format: '{value}%'
        }
      },
      tooltip: {
        useHTML: true,
        outside: true,
        formatter: function() {
          let tooltip = '<div style="width: 400px; white-space: normal;">'
          tooltip += Math.round(this.point.y * 100) / 100 + '% of learners passed this domain, dimension, or competency on their '+ this.series.name
          tooltip += '</div>'
          return tooltip
        } 
      },
      legend: {
        align: 'center',
        verticalAlign: 'top',
        symbolRadius: 0,
        squareSymbol: false,
        symbolHeight: 12,
        symbolWidth: 24
      },
      series: JSON.parse(this.element.dataset.series),
      exporting: {
        chartOptions: {
          chart: {
            width: 800,
            height: 800 
          }
        },
        scale: 1,
        buttons: {
          contextButton: {
            text: 'Download this graph',
            verticalAlign: "bottom",
            menuItems: [
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
            ],
            symbol: 'url(' + downloadSvg + ')',  // Custom symbol for the button
            symbolX: 20,
            symbolY: 20,
            symbolStrokeWidth: 1,
            theme: {
              style: {
                color: '#4462E9',
              },
              fill: '#FFFFFF',
              stroke: '#4462E9',
              strokeWidth: 2,
              states: {
                hover: {
                  fill: '#D3D3D3'
                },
                select: {
                  fill: '#D3D3D3'
                }
              }
            }
          }
        }
      }
    });
  }
}
