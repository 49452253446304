export function createConfirmationModal(options = {message: "", actionText: ""}) {  
  // Our current use case for creating modals on the client has us only really needing "modal-body".
  // If anyone is in need of the "modal-header" or "modal-footer" parts you'll have to add those here.

  let html = `
  <div class="modal fade">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex flex-column align-items-center">
            <p class="font-weight-bold">${options.message}</p>
            <button class="btn btn-primary px-5">${options.actionText}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  `

  let template = document.createElement("template")
  template.innerHTML = html
  return template.content.children[0] // Skip past the DocumentFragment and get the first actual node
}