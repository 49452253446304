import { Controller } from "stimulus"

const findScrollContainer = (element) => {
  if (!element) {
    return undefined;
  }

  let parent = element.parentElement;
  while (parent) {
    const { overflow } = window.getComputedStyle(parent);
    if (overflow.split(' ').every(o => o === 'auto' || o === 'scroll')) {
      return parent;
    }
    parent = parent.parentElement;
  }

  return document.documentElement;
};
const isVisible = function (ele, container) {
  const eleTop = ele.offsetTop;
  const eleBottom = eleTop + ele.clientHeight;

  const containerTop = container.scrollTop;
  const containerBottom = containerTop + container.clientHeight;

  // The element is fully visible in the container
  return (
    (eleTop >= containerTop && eleBottom <= containerBottom)
  );
};

export default class extends Controller {
  scroll(){
    setTimeout(() => {
      let container = findScrollContainer(this.element)
      if (isVisible(this.element, container)){
        return
      }
      this.element.scrollIntoView({
        behavior: "smooth"
      });
    }, parseInt(this.data.get("timeout")))
    
  }

}