import { Controller } from "stimulus"
import flatpickr from "flatpickr";

export default class extends Controller {
  connect() {
    let altFormat = this.element.dataset.formatValue || "F j, Y"

    this.flatpickrInstance = flatpickr(this.element, {
      allowInput: true,
      altInput: true,
      altFormat: altFormat
    });
  }

  disconnect() {
    if (this.flatpickrInstance) {
      this.flatpickrInstance.destroy();
    }
  }
}