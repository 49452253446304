import { Controller } from "stimulus"
import { EditorView, lineNumbers } from "@codemirror/view";
import {bbedit} from "@uiw/codemirror-theme-bbedit"
import {html} from "@codemirror/lang-html"

export default class extends Controller {
  connect() {
    let view = new EditorView({doc: this.element.value, extensions: [
      bbedit,
      lineNumbers(),
      html()
    ]})
    this.element.parentNode.insertBefore(view.dom, this.element)
    this.element.style.display = 'none'

    if (this.element.form) {
      this.element.form.addEventListener("submit", () => {
        this.element.value = view.state.doc.toString()
      })
    }
  }
}