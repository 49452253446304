import { Controller } from "stimulus";
import Picker from 'vanilla-picker';
export default class extends Controller {
  connect() {
    this.element.type = "hidden";
    let divEl = document.createElement("div");
    divEl.style.display = "inline-block";
    divEl.style.height = "24px";
    divEl.style.width = "72px";
    divEl.style.backgroundColor = `#${this.element.value}`;
    divEl.style.border = "1px solid black";
    this.element.parentElement.appendChild(divEl);

    this.picker = new Picker({
      parent: divEl,
      color: this.element.value,
      alpha: false,
      onChange: (color) => {
        divEl.style.backgroundColor = color.hex
        let hexWithNoHash = color.hex.substring(1)
        let hexWithNoAlpha = hexWithNoHash.substring(0, hexWithNoHash.length - 2)
        this.element.value = hexWithNoAlpha
      }
    })
  }
}