import { Controller } from "stimulus"
// this controller handles appending the correct ending 'hash' to the browser url
// so that when the page reloads you are redirected to the last tab you were on

export default class extends Controller {
  connect() {
    if (location.hash !== '') {
      let tab = this.element.querySelector(`a[href="${location.hash}"]`)
      if (tab) {
        $(tab).tab('show')
      }
    }
  }
}