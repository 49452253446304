import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "primaryInput", "secondaryInput" ] 
  
  connect() {
    // we copy the children like this so we copy by value instead of reference
    this.secondaryInputOptions = [...this.secondaryInputTarget.children]
    this.update()
  }

  update() {
    this.secondaryInputTarget.innerHTML = "<option value></option>"
    
    let key = this.primaryInputTarget.options[this.primaryInputTarget.selectedIndex].dataset.id
    if (key !== undefined) {
      for(const option of this.secondaryInputOptions.filter(option => option.dataset.parentId == key)) {
        let appendedChild = this.secondaryInputTarget.appendChild(option)
      }
      this.secondaryInputTarget.dispatchEvent(new Event('change'))
    }
  }
}