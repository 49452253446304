import { Controller } from "stimulus"

export default class extends Controller {
  toggleAll(event) {
    const checkboxes = this.element.querySelectorAll('[data-select-all-target="checkbox"]');
    const selectAllChecked = event.target.checked;
    checkboxes.forEach(checkbox => {
      checkbox.checked = selectAllChecked;
    });
  }
}